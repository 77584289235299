.champ__about {
  display: flex;
  justify-content: center;
  z-index: 2;
}
.champ__text {
  width: 50%;
  padding: 4rem 2rem;
}
.champ__text h2 {
  font-size: var(--step-2);
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  width: max-content;
  line-height: 1;
  margin-bottom: 2rem;
  color: var(--color-secondary);
  overflow-wrap: break-word;
  width: fit-content;
}
.champ__text h2::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  background: var(--color-primary);
  z-index: -1;
}
.champ__text p {
  margin-top: 1rem;
  font-size: var(--step--1);
  font-weight: 200;
  color: var(--color-secondary);
  letter-spacing: 1px;
  line-height: 1.5;
}

.champ__animations {
  width: 50%;
  padding: 2rem 4rem;
}
.champ_animation_item {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px 20px;
}
.champ_animation_item .champ_animation_image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 10.3px 13.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086);
}
.champ_animation_item .champ_animation_image img,
.champ_animation_item .champ_animation_image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  border-radius: 20px;
}

/***** MEDIA QUERY *****/

@media screen and (max-width: 62rem) {
  .champ__about {
    display: flex;
    flex-direction: column;
  }
  .champ__text,
  .champ__animations {
    width: 100%;
  }
  .white-bg {
    display: none;
  }
  .champ_animation_item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 20px 20px;
  }
  .champ__text {
    padding: 2rem 2rem;
  }
  .champ__animations {
    padding: 2rem 2rem;
  }
}
@media screen and (max-width: 1308px) {
  .champ__text h2::before {
    bottom: -20px;
    height: 10px;
  }
}
@media screen and (max-width: 62rem) {
  /* .champ__text h2::before {
    position: absolute;
  } */
}
@media screen and (max-width: 43rem) {
  .champ_animation_item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 20px 20px;
  }
  .champ__text {
    padding: 2rem 1rem;
  }
  .champ__animations {
    padding: 2rem 1rem;
  }
}
@media screen and (max-width: 475px) {
  .champ__text h2::before {
    bottom: -20px;
    height: 15px;
  }
}
