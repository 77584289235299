#top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-main);
  position: absolute;
  width: 1440px;
  max-width: 100%;
  z-index: 5;
}
.champ__logo {
  width: 50%;
}
.champ__logo img {
  width: 100%;
  max-width: 400px;
  height: auto;
}
.champ__social-header {
  width: 100%;
  max-width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.champ__social-header svg {
  width: 50px;
  height: 50px;
  color: var(--color-primary);
  margin-right: 1.2rem;
  transition: all 0.4s ease-in-out;
  stroke-width: 15;
  stroke: #b39e4a;
}
.champ__social-header svg:hover {
  color: var(--color-secondary);
  transform: scale(1.1);
  stroke: var(--color-secondary);
}
.mint_btn {
  padding: 5px 2.5rem;
  font-size: var(--step--1);
  letter-spacing: 1px;
  font-weight: 300;
}

@media screen and (max-width: 62rem) {
  #top {
    --padding-main: 2rem 2rem;
  }
}

@media screen and (max-width: 43rem) {
  .champ__social-header svg {
    display: none;
  }
  #top {
    --padding-main: 2rem 1rem;
  }
}
