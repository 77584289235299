.faqs {
  padding: var(--padding-main);
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.faqs .faq {
  width: 100%;
  background: #f8f8f8;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid var(--color-secondary);
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease-in-out;
  color: var(--color-secondary);
  letter-spacing: 1px;
  line-height: 1.5;
  font-family: 'Fuzzy Bubbles', cursive;
}
.faq {
  cursor: pointer;
}
.faqs .faq .faq-question {
  position: relative;
  font-size: var(--step--1);
  font-weight: 200;
  padding-right: 80px;
  transition: all 0.4s ease;
}
.faqs .faq .faq-question::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-image: url('../../assets/bi_arrow-down-circle.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  font-size: var(--step--1);
  font-weight: 200;

  /* transition: all 0.4s ease-out; */
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
  padding: 1rem;
  background: var(--color-bg-2);
  border-radius: 15px;
}
.faqs_header {
  padding: var(--padding-main);
}
.faqs_header h2 {
  font-size: var(--step-2);
  font-weight: 800;
  color: var(--color-secondary);
  text-transform: uppercase;
  position: relative;
  width: max-content;
  z-index: 5;
  line-height: 1;
}
.faqs_header h2::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 18px;
  background: var(--color-primary);
  z-index: -1;
}

.faqs_header.champ_page_width {
  padding-bottom: 0px;
}
/****MEDIA QUERIES ****/
@media screen and (max-width: 62rem) {
  .faqs_header,
  .faqs {
    --padding-main: 2rem 2rem;
  }
}

@media screen and (max-width: 43rem) {
  .faqs_header,
  .faqs {
    --padding-main: 2rem 1rem;
  }
}
