@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@400;700&display=swap');

:root {
  --font-family: 'Poppins', sans-serif;

  --color-bg: #fff;
  --color-bg-2: #f1ede4;
  --color-primary: #e5ca5e;
  --color-secondary: #353648;

  --padding-main: 2rem 4rem;
}

h1,
h2,
h3,
h4 {
  font-family: 'Fuzzy Bubbles', cursive;
}
p {
  font-family: 'Fuzzy Bubbles', cursive;
}
.champ_page_width {
  width: 1440px;
  max-width: 100%;
}
.champ_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-bg-2);
  position: relative;
  margin-bottom: 2rem;
  padding: 2rem 0;
  overflow: hidden;
}
@media screen and (max-width: 62rem) {
  .champ_section {
    margin-bottom: 0px;
  }
}
.white-bg {
  width: 50%;
  position: absolute;
  right: 0;
  height: 100%;
  background: white;
  z-index: 1;
}
.champ_btn {
  background: var(--color-secondary);
  border: 3px solid var(--color-primary);
  border-radius: 20px;
  color: white;
  font-family: 'Fuzzy Bubbles', cursive;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.55);
  transition: all 0.4s ease;
}

.champ_btn:hover {
  background: var(--color-primary);
  color: var(--color-secondary);
}
#root
  > div
  > section:nth-child(3)
  > div.faqs.champ_page_width
  > div.faq.open
  > div.faq-answer {
  white-space: pre-line;
}

/*********FONTS RESPONSIVE **********/
:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1140;

  --fluid-screen: 100vw;
  --fluid-bp: calc(
    (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
      (var(--fluid-max-width) - var(--fluid-min-width))
  );
}

@media screen and (min-width: 1140px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --f--2-min: 14.58;
  --f--2-max: 15.36;
  --step--2: calc(
    ((var(--f--2-min) / 16) * 1rem) + (var(--f--2-max) - var(--f--2-min)) *
      var(--fluid-bp)
  );

  --f--1-min: 17.5;
  --f--1-max: 19.2;
  --step--1: calc(
    ((var(--f--1-min) / 16) * 1rem) + (var(--f--1-max) - var(--f--1-min)) *
      var(--fluid-bp)
  );

  --f-0-min: 21;
  --f-0-max: 24;
  --step-0: calc(
    ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
      var(--fluid-bp)
  );

  --f-1-min: 25.2;
  --f-1-max: 30;
  --step-1: calc(
    ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
      var(--fluid-bp)
  );

  --f-2-min: 30.24;
  --f-2-max: 37.5;
  --step-2: calc(
    ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
      var(--fluid-bp)
  );

  --f-3-min: 36.29;
  --f-3-max: 46.88;
  --step-3: calc(
    ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
      var(--fluid-bp)
  );

  --f-4-min: 43.55;
  --f-4-max: 58.59;
  --step-4: calc(
    ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
      var(--fluid-bp)
  );

  --f-5-min: 52.25;
  --f-5-max: 73.24;
  --step-5: calc(
    ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
      var(--fluid-bp)
  );
}
